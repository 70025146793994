import styled from 'styled-components';

export const DialogButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
`;

export const CenteredButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.8rem;
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Error = styled.div`
  display: flex;
  color: red;
  margin-top: 0.5rem;
`;

export const Link = styled.link``;

export const Heading = styled.h1`
  display: flex;
  font-size: 1.5rem;
`;

export const HeadingH1 = styled.h1`
  display: flex;
  font-size: 1.5rem;
  margin: 1rem 0;
`;
export const HeadingH2 = styled.h2`
  display: flex;
  font-size: 1.375rem;
  font-weight: normal;
  margin: 1rem 0;
`;
export const HeadingH3 = styled.h3`
  display: flex;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 1rem 0;
`;
export const HeadingH4 = styled.h4`
  display: flex;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 1rem 0;
`;

export const ButtonBar = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
