import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Error } from '../../styled/styled';

interface FieldValueCP {
  label: string;
  value: any;
  errorMessage?: string;
}
const HorizontalFieldValue: React.FC<FieldValueCP> = ({ label, value, errorMessage }) => {
  return (
    <>
      {value && (
        <FieldValueWrapper>
          <Label>{label}</Label>
          <Value>{value}</Value>
          {errorMessage && <Error>{errorMessage}</Error>}
        </FieldValueWrapper>
      )}
    </>
  );
};

const FieldValueWrapper = styled.div`
  padding: 5px 5px 5px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Label = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
const Value = styled.div`
  width: 100%;
  flex-grow: 1;
`;
export default HorizontalFieldValue;
